.accordion {
    border: none;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.accordion__item {
    width: 50%;
    padding: 10px;
    border: none;
}

.accordion__item + .accordion__item {
    border: none;
}
.accordion__panel{
    font-size: 16px;
    font-weight: 500;
    font-family: 'Circular';
}

.accordion__button {
    position: relative;
    padding: 10px 20px;
    padding-right: 28px;
    background-color: transparent;
    border: 1px solid #EEEEEE;
    border-radius: 5px;

    font-size: 18px;
    font-weight: bold;
    color: #000;
}

.accordion__button::before {
    transition: all 0.25s linear;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);

    color: #E7131A;
}

.accordion__button[aria-expanded='true']::before, 
.accordion__button[aria-selected='true']::before {
    transform: translateY(-50%) rotate(225deg);
}


@media only screen and (max-width: 700px) {
    .accordion__item {
        width: 100%;
    }
}