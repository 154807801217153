
@font-face {
    font-family: 'Circular';
    font-weight: 400 ;
    src: url('./fonts/CircularStd-Book.woff2'), url('./fonts/CircularStd-Black.ttf');
    font-display: swap;
  
}
@font-face {
    font-family: 'Circular-medium';
    font-weight: 600 ;
    src: url('./fonts/CircularStd-Medium.woff2'), url('./fonts/CircularStd-Medium.ttf');
    font-display: swap;

}
@font-face {
    font-family: 'Circular-bold';
    font-weight: 700 ;
    src: url('./fonts/CircularStd-Book.woff2'), url('./fonts/CircularStd-Book.ttf');
    font-display: swap;

}
@font-face {
    font-family: 'Circular-black';
    font-weight: 900 ;
    src: url('./fonts/CircularStd-Black.woff2'), url('./fonts/CircularStd-Black.ttf');
    font-display: swap;
  
 
}
* {
    /* font-family: "Circular-black", sans-serif !important; */
    font-family: 'Circular', sans-serif;
}

 body{
    overflow-x: hidden;
    font-family: 'Circular', sans-serif;

} 
a {
    color: #E7131A;
    text-decoration: none;
  }
  a:hover {
    color: #E7131A;
    /* text-decoration: underline; */
  }
  
  h1{
    font-family: "Circular-black";
    font-size: 3.75rem;
  }
  h2{
    font-family: "Circular-black";
    font-size: 3rem;
  }
  h3{
    font-family: "Circular-bold";
    font-size: 1.5rem;
  }
  
  p{
    font-size: 1rem;
   line-height: 1.5rem;
    color: rgba(0, 0, 0, 0.74);
  }
  
  /* section {
    min-height: 66vh;
    padding: 60px 0;
    overflow: hidden;
  }
   */
/* :root {
    scroll-behavior: smooth;
  }
  
  body {
    font-family: "Circular", sans-serif;
    color: black;
  }
  
  a {
    color: #E7131A;
    text-decoration: none;
  }
  
  a:hover {
    color: #E7131A;
    text-decoration: underline;
  }
  
  h1{
    font-family: "Circular-black";
    font-size: 3.75rem;
  }
  
  h2{
    font-family: "Circular-black";
    font-size: 3rem;
  }
  h2 span{
  
    color: #E7131A;
  }
  h3{
    font-family: "Circular-bold";
    font-size: 1.5rem;
  }
  
  p{
    font-size: 1rem;
  line-height: 1.5rem;
  color: rgba(0, 0, 0, 0.54);
  }
  
  section {
    min-height: 66vh;
    padding: 60px 0;
    overflow: hidden;
  }
   */